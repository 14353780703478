///<reference path="../../../../Scripts/typings/jquery/jquery.d.ts"/>

class PakketOverzichtRenderer
{
	static ValuesRendered()
	{
		const overzichten = document.querySelectorAll("div.pakketframe div.totaalpremie");

		$(overzichten).each(this.ApplyPakketOverzichtStyling);
	}

	static ApplyPakketOverzichtStyling(_: number, overzicht: Element)
	{
		let index = 1;

		$(overzicht)
			.find("div.property")
			.filter(":visible")
			.each(
			(_, propertyElement) =>
			{
				propertyElement.classList.remove("even");
				propertyElement.classList.remove("odd");

				const className = (index++ % 2 === 0) ? "even" : "odd";
				propertyElement.classList.add(className);
			}
			);
    }

    static HidePakketOverzicht(overzicht: Element) {
        $('.cartcontainer', overzicht).hide();
    }
    static ShowPakketOverzicht(overzicht: Element) {
        $('.cartcontainer', overzicht).show();
    }


}

// TODO: Zorgen dat de compiler dit automatisch doet?
(<any>window)["PakketOverzichtRenderer"] = PakketOverzichtRenderer;